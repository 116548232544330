import { Popover as MuiPopover } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const Popover = withStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    maxWidth: 200,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey.A400,
    marginLeft: theme.spacing(4),
    overflow: 'initial',
    '&:after': {
      content: "''",
      position: 'absolute',
      right: theme.spacing(3),
      bottom: -5,
      width: 0,
      height: 0,
      margin: 0,
      borderStyle: 'solid',
      borderWidth: '8px 8px 0 8px',
      borderColor: `${theme.palette.grey.A400} transparent transparent transparent`,
    },
  },
}))(({ open, anchor, onClose, children, ...props }) => {
  return (
    <MuiPopover
      {...props}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </MuiPopover>
  )
})

export default Popover
