import { makeRequired } from 'mui-rff'
import * as Y from 'yup'
import m from './messages'
import { makeValidate } from '../utils'

export const initialValues = {
  token: '',
}

export const schema = Y.object().shape({
  token: Y.string().min(6, m.token.length).required(m.token.required),
})

export const validate = makeValidate(schema)

export const required = makeRequired(schema)
