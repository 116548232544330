import React from 'react'
import LockIcon from '@material-ui/icons/Lock'

const messages = {
  token: {
    required: 'Código obrigatório',
    length: 'Código incompleto',
  },
}
export default messages

const warnings = {
  form_token_sms: {
    title: 'Confirmação via SMS',
    content: (
      <p id="alert-dialog-description">
        Digite aqui o{' '}
        <strong>código de 6 dígitos que você acabou de receber via SMS em seu celular</strong>
      </p>
    ),
    icon: <LockIcon />,
  },
  form_token_zap: {
    title: 'Confirmação via WhatsApp',
    content: (
      <p id="alert-dialog-description">
        Digite aqui o <strong>código de 6 dígitos que você acabou de receber via WhatsApp</strong>
      </p>
    ),
    icon: <LockIcon />,
  },
}

export { warnings }
