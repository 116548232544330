import { Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import { usePaymentContext } from 'context/PaymentContext'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideForm, selectPayments, setMethodIndex } from 'reducers/payments'
import hasInterestAndFines from 'utils/helpers/hasInterestAndFines'
import invoiceStatus from 'utils/invoiceStatus'
import payableWith, { Payment_Methods } from 'utils/payableWith'
import useRudderStack from 'utils/useRudderStack'
import { BankSlipMethod } from './BankSlipMethod'
import { installmentDefault } from './CreditCardForm'
import { CreditCardMethod } from './CreditCardMethod'
import { PixMethod } from './PixMethod'
import {
  BankSlipButton,
  CreditPaymentButton,
  FlowBanner,
  HeaderBanner,
  PaymentBox,
  PaymentCloseIcon,
  PixPaymentButton,
} from './styles'

export const PaymentTabSelector = () => {
  const { userData, invoiceData, setInvoiceData } = usePaymentContext()
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const payments = useSelector(selectPayments)
  const dispatch = useDispatch()
  const onCloseForm = useCallback(() => {
    dispatch(hideForm())
  }, [dispatch])
  const selectMethod = (index) => {
    dispatch(setMethodIndex(index))
  }

  const showAmountWithInterestAndFines =
    hasInterestAndFines(invoiceData) && invoiceData?.status === invoiceStatus.OVERDUE

  const invoiceAmount = showAmountWithInterestAndFines
    ? invoiceData?.amount?.total
    : invoiceData.info?.amount

  const payableWithData = payableWith(invoiceData)
  const selectUniqueMethod = () => {
    switch (payableWithData[0]) {
      case Payment_Methods.pix:
        {
          selectMethod(1)
        }
        break
      case Payment_Methods.bank_slip:
        {
          selectMethod(2)
        }
        break
      default: {
        selectMethod(0)
      }
    }
  }
  const multipleMethods = payableWithData.length > 1
  const isMobile = useMediaQuery('(max-width:600px)')
  const installments = invoiceData?.dynamicParams?.installments || 1

  useEffect(() => {
    if (!multipleMethods) {
      selectUniqueMethod()
    } else if (
      payableWithData[0] === Payment_Methods.pix ||
      payableWithData[0] === Payment_Methods.bank_slip
    ) {
      selectMethod(1)
      setInvoiceData({
        ...invoiceData,
        dynamicParams: { installments: installmentDefault, paymentMethod: Payment_Methods.pix },
      })
    }
  }, [multipleMethods])

  return (
    <>
      <PaymentBox>
        <Hidden mdUp>
          <HeaderBanner
            name={invoiceData?.info?.reference || 'Valor da cobrança'}
            handle={userData?.id}
            dynamicParams={invoiceData?.dynamicParams}
            valueWithFees={invoiceData?.value_with_fees?.capture_methods}
            installments={installments}
            invoiceAmount={invoiceAmount}
            selectedMethod={payments.methodIndex}
          />
        </Hidden>
        {/* <Hidden smDown> */}
        <PaymentCloseIcon position="absolute" right="3rem" top="0.6rem" onCloseForm={onCloseForm} />
        {/* </Hidden> */}
        <div className="tab-selector" style={{ paddingTop: !isMobile && '48px' }}>
          <Grid container spacing={0}>
            {multipleMethods && (
              <Grid item xs={12} style={multipleMethods ? { margin: '0 0 32px 0' } : {}}>
                <Typography variant="h6">
                  {multipleMethods ? 'Como você quer pagar?' : 'Pagar compra'}
                </Typography>
              </Grid>
            )}
            {multipleMethods ? (
              <Grid container style={{ margin: '0 -8px' }} spacing={2}>
                {payableWithData.includes(Payment_Methods.credit_card) && (
                  <Grid item xs={4}>
                    <CreditPaymentButton
                      onClick={() => {
                        if (payments.methodIndex !== 0) {
                          rudderStackReady &&
                            rudderStack?.track('Credit card payment selection', {
                              invoice_id: invoiceData.slug,
                            })
                          selectMethod(0)
                          setInvoiceData({
                            ...invoiceData,
                            dynamicParams: {
                              installments: installmentDefault,
                              paymentMethod: Payment_Methods.credit_card,
                            },
                          })
                        }
                      }}
                      selected={payments.methodIndex == 0}
                    />
                  </Grid>
                )}
                {payableWithData.includes(Payment_Methods.pix) && (
                  <Grid item xs={4}>
                    <PixPaymentButton
                      onClick={() => {
                        if (payments.methodIndex !== 1) {
                          rudderStackReady &&
                            rudderStack?.track('Pix payment selection', {
                              invoice_id: invoiceData.slug,
                            })
                          selectMethod(1)
                          setInvoiceData({
                            ...invoiceData,
                            dynamicParams: {
                              installments: installmentDefault,
                              paymentMethod: Payment_Methods.pix,
                            },
                          })
                        }
                      }}
                      selected={payments.methodIndex == 1}
                    />
                  </Grid>
                )}
                {payableWithData.includes(Payment_Methods.bank_slip) && (
                  <Grid item xs={4}>
                    <BankSlipButton
                      onClick={() => {
                        if (payments.methodIndex !== 2) {
                          rudderStackReady &&
                            rudderStack?.track('Bank slip payment selection', {
                              invoice_id: invoiceData.slug,
                            })
                          selectMethod(2)
                          setInvoiceData({
                            ...invoiceData,
                            dynamicParams: {
                              installments: installmentDefault,
                              paymentMethod: Payment_Methods.bank_slip,
                            },
                          })
                        }
                      }}
                      selected={payments.methodIndex == 2}
                    />
                  </Grid>
                )}
              </Grid>
            ) : null}

            {payments.methodIndex == 0 && <CreditCardMethod />}
            {payments.methodIndex == 1 && <PixMethod />}
            {payments.methodIndex == 2 && <BankSlipMethod />}
          </Grid>
        </div>
      </PaymentBox>
      <Hidden mdUp>
        <FlowBanner />
      </Hidden>
    </>
  )
}
