import { forwardRef, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box, Link } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectSession } from 'reducers/session'

import { AUTHENTICATORS } from 'libs/infinitepay/link'
import useRudderStack from 'utils/useRudderStack'

export const styles = (theme) => ({
  container: {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.grey[600],
    '& a': {
      cursor: 'pointer',
    },
  },
  link: {
    color: theme.palette.primary.alt,
  },
})

const TokenAction = forwardRef(function TokenActionImpl(
  { onEdit, onAction, classes, ...props },
  ref,
) {
  const { rudderStack, ready: readyRudderStack } = useRudderStack()
  const session = useSelector(selectSession)
  const onEditClick = useCallback(() => {
    if (readyRudderStack && rudderStack) {
      rudderStack.track('Invoice ReToken - edit number')
    }
    onEdit()
  }, [readyRudderStack, rudderStack])
  return (
    <Box {...props} className={classes.container} ref={ref}>
      Não recebeu o código?
      <br />
      Deseja <Link onClick={onEditClick}>editar número</Link> <>ou enviar novamente por: </>
      <Link onClick={() => onAction(AUTHENTICATORS.sms)}>SMS</Link> ou{' '}
      <Link onClick={() => onAction(AUTHENTICATORS.zap)}>WhatsApp</Link>
      {session.confirmation && session.confirmation.token && (
        <Box>
          <br />
          {session.confirmation.token}
        </Box>
      )}
    </Box>
  )
})

export default withStyles(styles, { name: 'TokenAction' })(TokenAction)
