import { Box, ClickAwayListener, Grid, Snackbar, Tooltip, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import AlertIcon from 'assets/system-alert.svg'
import Form from 'components/ui/form/BankSlipForm'
import Popup from 'components/ui/Popup'
import config from 'config'
import { usePaymentContext } from 'context/PaymentContext'
import format from 'date-fns/format'
import { ptBR } from 'date-fns/locale'
import invoiceApi from 'libs/infinitepay/invoice'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideForm } from 'reducers/payments'
import isDebug from 'utils/helpers/isDebug'
import maskDigitableLine from 'utils/maskDigitableLine'
import useRudderStack from 'utils/useRudderStack'
import { BankSlipForm } from './BankSlipForm'
import {
  BankSlipAlert,
  BankSlipData,
  BankSlipDownloadAlert,
  BankSlipError,
  BankSlipLoading,
  CopyButton,
  DownloadBankSlipBtn,
  WarningBox,
} from './styles'

const BANK_SKIP_END_PAYMENT = isDebug() ? 11 : 22

export const BankSlipMethod = ({}) => {
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const [data, setData] = useState(null)
  const { invoiceData, userData, setInvoiceData } = usePaymentContext()
  const router = useRouter()
  const { 'md[rid]': rid } = router.query
  const [open, setOpen] = useState(false)
  const [openDownloadSnack, setOpenDownloadSnack] = useState(false)
  const [dueDate, setDueDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [payerCPF, setPayerCPF] = useState()
  const [openPopupError, setOpenPopupError] = useState(false)

  const isMobile = useMediaQuery('(max-width:600px)')
  const dispatch = useDispatch()

  const handleTooltipOpen = () => {
    if (data === -1 && loading) return
    setOpen(true)
    navigator.clipboard.writeText(data)
  }

  const handleDownload = async () => {
    if (userData.id && invoiceData.slug) {
      const downloadURL = `${config.invoiceApiEndpoint}/public/bank_slip/pdf/${userData.id}/${invoiceData.slug}`
      setOpenDownloadSnack(true)
      window.open(downloadURL, '_blank')
    }
  }

  const handleTooltipClose = () => {
    open && setOpen(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleCloseDownload = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenDownloadSnack(false)
  }

  const onClosePopup = () => setOpenPopupError(false)

  const [canPayWithBankSlip, setCanPayWithBankSlip] = useState(true)

  useEffect(() => {
    const currentTime = new Date()
    const hour = currentTime.getHours()
    const dueDate = new Date(invoiceData?.bank_slip?.due_date || invoiceData.due_date)
    if (
      hour >= BANK_SKIP_END_PAYMENT &&
      dueDate.toLocaleDateString() === currentTime.toLocaleDateString()
    ) {
      setCanPayWithBankSlip(false)
    }
  }, [])

  const onCloseForm = useCallback(() => {
    dispatch(hideForm())
  }, [dispatch])

  useEffect(() => {
    if (invoiceData?.bank_slip?.digitable_line && rudderStackReady) {
      invoiceApi.renewProgressiveLongPolling()
      setData(maskDigitableLine(invoiceData.bank_slip.digitable_line))
      setLoading(false)
      const getDueDate = invoiceData?.bank_slip?.due_date || invoiceData.due_date

      const dueDateFormmated = format(new Date(getDueDate), 'dd/MM/yyyy', {
        locale: ptBR,
      })
      if (rudderStackReady) {
        rudderStack?.track('Invoice Bank Slip Viewed', { invoice_id: invoiceData.slug })
      }
      setDueDate(dueDateFormmated)
    }
  }, [invoiceData, invoiceData?.bank_slip?.digitable_line, rudderStackReady])

  const onSubmit = (values) => {
    setPayerCPF(values.bank_slip_document)
    setLoading(true)
    const createBankSlipInvoice = async () => {
      const customer = {
        name: values.bank_slip_name,
        document_number: values.bank_slip_document.replace(/\D/g, ''),
      }
      const response = await invoiceApi.createBankSlipInvoice(
        userData.id,
        invoiceData.slug,
        customer,
        rid,
      )
      if (response?.success) {
        setInvoiceData(response.data)
      } else {
        setData(-1)
        if (response?.reason === 'bank_slip_limit') {
          setOpenPopupError(true)
        }
        setLoading(false)
      }
    }
    createBankSlipInvoice()
  }
  const showForm = !(invoiceData?.bank_slip?.digitable_line || payerCPF)

  if (invoiceData.cannot_create_bank_slip && invoiceData.info.amount < 50) {
    return (
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <WarningBox
          title={'Ei, não é possível gerar boletos nesse valor'}
          data={'Você não pode mais gerar boletos com valor inferior à R$50,00'}
        />
      </Grid>
    )
  } else if (!canPayWithBankSlip) {
    return (
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <WarningBox
          title={'Ei, já passou das 22h'}
          data={
            'O pagamento via boleto fica indisponível depois deste horário, utilize outra forma'
          }
        />
      </Grid>
    )
  } else if (showForm) {
    return <Form onSubmit={onSubmit} onCloseForm={onCloseForm} component={BankSlipForm} />
  } else {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            {loading && <BankSlipLoading />}
            {data === -1 && <BankSlipError />}
            {!loading && data && data !== -1 && <BankSlipData data={data} />}
          </Grid>
          <Grid item style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} xs={12}>
            <ClickAwayListener disableReactTree={true} onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                arrow
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado!"
              >
                <div>
                  <CopyButton
                    disabled={data === -1 || loading}
                    onClick={handleTooltipOpen}
                    loading={loading}
                    style={{ height: '36px' }}
                  >
                    Copiar código
                  </CopyButton>
                </div>
              </Tooltip>
            </ClickAwayListener>
            <DownloadBankSlipBtn
              disabled={data === -1 || loading}
              onClick={handleDownload}
              loading={loading}
              style={{ height: '36px' }}
            >
              Baixar PDF
            </DownloadBankSlipBtn>
          </Grid>

          {!loading && data && data !== -1 && (
            <>
              <hr style={{ width: '100%', border: '1px solid #E0E0E0', margin: '40px 12px 0' }} />
              <Grid
                item
                xs={12}
                style={{ marginTop: '12px', padding: !isMobile && '0px !important' }}
              >
                <Box color="#616161" fontWeight="400" fontSize="15px">
                  <Box marginBottom="16px">
                    Seu pagamento pode levar até 2 dias úteis para ser processado. A página atualiza
                    com o seu comprovante. Você também recebe um e-mail de confirmação
                  </Box>
                  <Box fontSize="16px" color="#000" fontWeight="500" width="295px">
                    {dueDate && `Seu boleto vence em ${dueDate}`}
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <BankSlipAlert onClose={handleClose} />
          </Snackbar>
          <Snackbar
            open={openDownloadSnack}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <BankSlipDownloadAlert onClose={handleCloseDownload} />
          </Snackbar>
        </Grid>

        <Popup
          open={openPopupError}
          fullScreen={matchesDownSm}
          maxWidth={matchesMd && 'sm'}
          icon={AlertIcon}
          button={'OK'}
          title={'Ei, não é possível gerar boletos nesse valor'}
          onClose={onClosePopup}
        >
          <Box>Você não pode mais gerar boletos com valor inferior à R$50,00</Box>
        </Popup>
      </>
    )
  }
}
