import { withStyles } from '@material-ui/core/styles'
import { initialValues, validate, required, schema as formSchema } from './schema'
import BaseForm, { TextField, useFormSchemaContext } from '../'
import createDecorator from 'final-form-focus'
import { maskCPFOrCNPJ } from 'utils/form-masks'

const focusOnError = createDecorator()
const Form = ({ ...props }) => {
  return (
    <BaseForm
      decorators={[focusOnError]}
      {...props}
      {...{ required, validate, schema: formSchema, initialValues }}
    />
  )
}
export default Form

const labelSettings = () => ({
  root: {
    '& label': {
      pointerEvents: 'initial',
    },
  },
})

export const Name = withStyles(labelSettings())(
  ({ name = 'bank_slip_name', label = 'Nome', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        inputProps={{
          id: name,
          mask: /^[A-Za-zÀ-ÖØ-öø-ÿ'˜`´ˆ\s]+$/,
          indeterminate: true,
          autoComplete: 'cc-name',
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Nome vinculado ao boleto"
        required={schema.required[name]}
      />
    )
  },
)

export const CPF = withStyles(labelSettings())(
  ({ name = 'bank_slip_document', label = 'Confirme o CPF/CNPJ', ...props }) => {
    const schema = useFormSchemaContext()

    const handleInputChange = (event) => {
      const { value } = event.target
      const mask = maskCPFOrCNPJ(value)

      event.target.value = mask
    }

    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="text"
        inputProps={{
          id: name,
          autoComplete: 'on',
          inputMode: 'numeric',
          onChange: handleInputChange,
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite seu CPF/CNPJ aqui"
        required={schema.required[name]}
      />
    )
  },
)
