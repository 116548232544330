import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FORM_ERROR } from 'final-form'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { selectPayments, createPayment, hideForm, setMethodIndex } from 'reducers/payments'
import { selectSession } from 'reducers/session'
import { usePaymentContext } from 'context/PaymentContext'
import ThreeDS from 'components/ThreeDS'

import Popup from 'components/ui/Popup'
import Popover from 'components/ui/Popover'
import { useRouter } from 'next/router'
import Form from 'components/ui/form/PaymentForm'
import { warnings } from 'components/ui/form/PaymentForm/messages'
import { createPayload } from 'components/ui/form/PaymentForm/schema'
import TokenForm from 'components/TokenForm'
import useRudderStack from 'utils/useRudderStack'

import { CreditCardForm } from './CreditCardForm'

export const CreditCardMethod = ({}) => {
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const config = usePaymentContext()
  const session = useSelector(selectSession)
  const payments = useSelector(selectPayments)
  const dispatch = useDispatch()
  const router = useRouter()
  const { 'md[rid]': rid } = router.query

  const [popup, setPopup] = useState({
    open: false,
  })
  const { info: infoPopup, open: openPopup, anchor, unknownError } = popup

  const [tokenForm, setTokenForm] = useState({
    open: false,
  })
  const { open: openTokenForm } = tokenForm

  const onCloseForm = useCallback(() => {
    dispatch(hideForm())
  }, [dispatch])

  const onFormClose = useCallback((event) => {
    setTokenForm({
      open: false,
    })
  }, [])

  const onPopupClose = useCallback(
    (event) => {
      if (unknownError) {
        location.reload()
      } else {
        setPopup((current) => {
          return {
            ...current,
            open: false,
            anchor: null,
          }
        })
      }
    },
    [unknownError],
  )

  const onHelperClick = useCallback(
    (event) => {
      onPopupClose()
      setTimeout(() => {
        setPopup(() => {
          return {
            open: true,
            info: warnings.cvv_help,
          }
        })
      }, 100)
    },
    [onPopupClose, setPopup],
  )

  const onSubmit = async (values, form) => {
    const payload = createPayload(values, { ...config.invoiceData, rid })
    const handle = config.userData.id
    const amount = config.userData.amount?.value
    if (rudderStackReady) {
      rudderStack.track('Invoice Pay Button', {invoice_id: config.invoiceData.slug})
    }
    const response = await dispatch(
      createPayment({
        handle,
        amount,
        ...payload,
        router,
        rudderStack,
      }),
    )
    const { meta, payload: result } = response
    if (meta.condition === false) {
      if (result && result?.error?.startsWith('Email ')) {
        return {
          email: result.error,
        }
      }
      if (result == 'unknown_error') {
        showFinalError()
        return false
      }
      return {
        [FORM_ERROR]: result?.error || result,
      }
    }
  }
  const showFinalError = () => {
    rudderStack.track('Invoice Error', {
      error: 'Unknown Error - PAY CONNECTION',
    })
    setPopup(() => {
      return {
        open: true,
        unknowError: true,
        info: warnings.unknown_error,
      }
    })
  }
  useEffect(() => {
    setTokenForm({
      open: Boolean(session.confirmation),
    })
  }, [session.confirmation])

  return (
    <>
      <Form
        onSubmit={onSubmit}
        onCloseForm={onCloseForm}
        component={CreditCardForm}
        closePopup={onPopupClose}
        handlePopup={setPopup}
      />
      <Popup
        fullScreen={matchesDownSm}
        maxWidth={matchesMd && 'sm'}
        open={openPopup && !anchor}
        title={infoPopup?.title}
        icon={infoPopup?.icon}
        button={infoPopup?.button}
        onClose={onPopupClose}
        onHelperClick={onHelperClick}
      >
        {infoPopup?.content ?? ''}
      </Popup>
      <Popover open={!!(anchor && openPopup)} anchor={anchor} onClose={onPopupClose}>
        {infoPopup?.content ?? ''}
      </Popover>
      <TokenForm open={openTokenForm} onClose={onFormClose} />
      <ThreeDS />
    </>
  )
}
