import { Grid, Typography } from '@material-ui/core'
import FooterInterestAndFines from 'components/FooterInterestAndFines'
import { SubmitButton } from 'components/ui/form'
import { CPF, Name } from 'components/ui/form/BankSlipForm'
import { usePaymentContext } from 'context/PaymentContext'

export const BankSlipForm = ({ handleSubmit }) => {
  const { invoiceData } = usePaymentContext()
  return (
    <>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit} noValidate autoComplete="on">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Confirme o CPF/CNPJ vinculado a cobrança:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Name />
          </Grid>
          <Grid item xs={12}>
            <CPF />
          </Grid>
          <Grid item xs={12}>
            <br />
            <SubmitButton disabled={false}>Gerar Boleto</SubmitButton>
          </Grid>
          <FooterInterestAndFines invoiceData={invoiceData} />
        </Grid>
      </form>
    </>
  )
}
