import { useSelector } from 'react-redux'

import { selectPayments } from 'reducers/payments'

import { FormBox } from './styles'

import { PaymentTabSelector } from './PaymentTabSelector'

function PaymentForm({ ...props }) {
  const payments = useSelector(selectPayments)
  return (
    <FormBox component="section" expand={payments.active} {...props}>
      <PaymentTabSelector />
    </FormBox>
  )
}
export default PaymentForm
