const messages = {
  bank_slip_name: {
    required: 'Nome obrigatório',
  },
  bank_slip_document: {
    required: 'CPF/CNPJ obrigatório',
    length: 'CPF/CNPJ incompleto',
    invalid: 'CPF/CNPJ inválido',
  },
}
export default messages
