import axios from 'axios'
import config from 'config'
import invoiceStatus from 'utils/invoiceStatus'
import link from './link'

const DEFAULT_LONG_POLLING = () => [5, 5, 10, 10, 10, 20, 20, 20, 20, 40, 40, 40, 40, 40]

class InvoiceAPI {
  static _instance = null

  #invoiceApi = axios.create({
    baseURL: config.invoiceApiEndpoint,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  constructor() {
    this.progressiveLongPolling = DEFAULT_LONG_POLLING()
    return (InvoiceAPI._instance ||= this)
  }

  async fetchPublicInvoice(handle, amount, rid, options = null) {
    try {
      options = options || {}
      if (!!rid) {
        options.params = { 'md[rid]': rid }
      }
      const response = await this.#invoiceApi.get(`/public/${handle}/${amount}`, options)
      return response.data
    } catch (err) {}
    return null
  }

  async listenPublicInvoice(path) {
    try {
      const response = await this.#invoiceApi.get(`/public/listen${path}`, {
        headers: link.mergeHeaders({}),
      })
      return response.data
    } catch (err) {}
    return null
  }

  async pollingInvoice(path, callback) {
    const interval = setTimeout(async () => {
      const invoice = await this.listenPublicInvoice(path)

      if (
        invoice &&
        (invoice?.data?.status !== invoiceStatus.PENDING &&
          invoice?.data?.status !== invoiceStatus.OVERDUE)
      ) {
        callback(invoice)
      } else {
        this.pollingInvoice(path, callback)
      }
    }, (this.progressiveLongPolling.shift() || 60) * 1000)
  }

  renewProgressiveLongPolling() {
    this.progressiveLongPolling = DEFAULT_LONG_POLLING()
  }

  async createPixInvoice(handle, slug, rid) {
    try {
      const response = await this.#invoiceApi.post(
        `/public/pix`,
        {
          slug: slug,
          user: {
            handle: handle,
          },
          'md[rid]': rid || null,
        },
        { headers: link.mergeHeaders({}) },
      )
      return response.data
    } catch (err) {}
    return null
  }

  async createBankSlipInvoice(handle, slug, customer, rid) {
    try {
      const response = await this.#invoiceApi.post(
        `/public/bank_slip`,
        {
          slug: slug,
          user: {
            handle: handle,
          },
          customer: customer,
          'md[rid]': rid || null,
        },
        { headers: link.mergeHeaders({}) },
      )
      return response.data
    } catch (err) {
      return err.response.data
    }
  }
}

export default new InvoiceAPI()
