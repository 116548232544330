import Loading from "components/Loading"


function LoadingContainer() {
  return (
    <Loading />
  )
}

export default LoadingContainer
