import { useSelector } from 'react-redux'
import Challenge from './Challenge'
import FormPost from './FormPost'
import { selectThreeDS } from 'reducers/threeds'

const ThreeDS = () => {
  const threeds = useSelector(selectThreeDS)
  return (
    <>
      {threeds.form_post && <FormPost {...threeds.form_post} />}
      {threeds.challenge && <Challenge />}
    </>
  )
}
export default ThreeDS
