import NewHeader from 'components/NewHeader'
import LandingBox from 'components/LandingBox'

import { usePaymentContext } from 'context/PaymentContext'
import { useDispatch, useSelector } from 'react-redux'
import invoiceApi from '../libs/infinitepay/invoice'
import PaymentContainer from 'containers/PaymentContainer'
import useRudderStack from 'utils/useRudderStack'
import { useEffect } from 'react'
import invoiceStatus from 'utils/invoiceStatus'
import { selectRedirectReceipt, redirectToReceipt } from 'reducers/redirectReceipt'
import LoadingContainer from './LoadingContainer'
import { hideForm } from 'reducers/payments'

function LandingContainer(props) {
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const { userData, invoiceData, setInvoiceData } = usePaymentContext()
  const dispatch = useDispatch()
  const redirect = useSelector(selectRedirectReceipt)
  const renderPayment = !userData?.limit_exception && userData?.amount

  useEffect(() => {
    if (invoiceData?.receipt) {
      dispatch(redirectToReceipt(invoiceData.receipt.transactionId))
    }
  }, [invoiceData])

  useEffect(() => {
    if (
      props.invoiceData?.data?.path &&
      (props.invoiceData.data.status === invoiceStatus.PENDING || props.invoiceData.data.status === invoiceStatus.OVERDUE)
    ) {
      invoiceApi.pollingInvoice(props.invoiceData.data.path, (data) => {
        if (
          data.data.slug === invoiceData?.slug &&
          data.data.status !== invoiceStatus.PENDING &&
          data.data.status !== invoiceStatus.OVERDUE
        ) {
          setInvoiceData(data.data)
          dispatch(hideForm())
        }
      })
    }
  }, [])

  useEffect(() => {
    if (rudderStackReady) {
      rudderStack &&
        rudderStack.page('Invoice Page', { invoice_status: props.invoiceData?.data?.status })
    }
  }, [rudderStackReady])

  return (
    <>
      <LandingBox>
        <NewHeader />
        {renderPayment && <PaymentContainer />}
      </LandingBox>
      {redirect.active && <LoadingContainer />}
    </>
  )
}

export default LandingContainer
