export const maskDigitableLine = (digitableLine) => {
  /*
    Posição 01-03 = Identificação do banco (exemplo: 001 = Banco do Brasil)
    Posição 04-04 = Código de moeda (exemplo: 9 = Real)
    Posição 05-09 = 5 primeiras posições do campo livre (posições 20 a 24 do código de barras)
    Posição 10-10 = Dígito verificador do primeiro campo
    Posição 11-20 = 6ª a 15ª posições do campo livre (posições 25 a 34 do código de barras)
    Posição 21-21 = Dígito verificador do segundo campo
    Posição 22-31 = 16ª a 25ª posições do campo livre (posições 35 a 44 do código de barras)
    Posição 32-32 = Dígito verificador do terceiro campo
    Posição 33-33 = Dígito verificador geral (posição 5 do código de barras)
    Posição 34-37 = Fator de vencimento (posições 6 a 9 do código de barras)
    Posição 38-47 = Valor nominal do título (posições 10 a 19 do código de barras)
  */
  digitableLine = digitableLine.replace(/\D/g, '')
  if (digitableLine.length !== 47) {
    return digitableLine
  }

  return (
    digitableLine.substring(0, 5) +
    '.' +
    digitableLine.substring(5, 10) +
    ' ' +
    digitableLine.substring(10, 15) +
    '.' +
    digitableLine.substring(15, 21) +
    ' ' +
    digitableLine.substring(21, 26) +
    '.' +
    digitableLine.substring(26, 32) +
    ' ' +
    digitableLine.substring(32, 33) +
    ' ' +
    digitableLine.substring(33, 47)
  )
}

export default maskDigitableLine
