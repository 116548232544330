import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import BrlcLoading from './BrlcLoading'

const Loading = withStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    gap: theme.spacing(2),
    top: 0,
    left: 0,
    position: 'fixed',
    flexDirection: 'column',
  },
  textTypography: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '27.4px',
    color: '#000000',
  },
}))(({ open = true, classes, onClose, data, ...props }) => {
  return (
    <Box className={classes.container}>
      <BrlcLoading />
      <Typography className={classes.textTypography}>
        Carregando
      </Typography>
    </Box>
  )
})

export default Loading
