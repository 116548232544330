import { makeRequired } from 'mui-rff'
import * as Y from 'yup'
import m from './messages'
import { makeValidate } from '../utils'
import { validCPFOrCNPJ } from 'utils/form-validation'

export const initialValues = {
  bank_slip_name: '',
  bank_slip_document: '',
}

export const schema = Y.object().shape({
  bank_slip_name: Y.string().required(m.bank_slip_name.required),

  bank_slip_document: Y.string()
    .transform(function (value, originalValue) {
      return validCPFOrCNPJ(value) && this.isType(value) ? originalValue.replace(/\D/gi, '') : null
    })
    .typeError(m.bank_slip_document.invalid)
    .min(11, m.bank_slip_document.length)
    .required(m.bank_slip_document.required),
})

export const validate = makeValidate(schema)

export const required = makeRequired(schema)
