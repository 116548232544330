const { ENV_NAME } = process.env;

export const isTest = () => {
  return (
    typeof ENV_NAME === 'undefined' ||
    ENV_NAME.includes('test') ||
    ENV_NAME.includes('sandbox')
  );
};

export const isDebug = () => {
  return ENV_NAME !== 'production' && !isTest();
};

export const isStaging = () => ENV_NAME === 'staging';
export const isProd = () => ENV_NAME === 'production';

export default isDebug;
