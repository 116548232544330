import { usePaymentContext } from 'context/PaymentContext'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { sendEnrollment } from 'reducers/threeds'

/*
Teste: https://centinelapistag.cardinalcommerce.com 
Produção: https://centinelapi.cardinalcommerce.com
*/
const allowedOrigins = [
  'https://centinelapistag.cardinalcommerce.com',
  'https://centinelapi.cardinalcommerce.com',
]

const FormPost = ({ action, jwt }) => {
  const config = usePaymentContext()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef.current])

  useEffect(() => {
    const handler = (event) => {
      if (allowedOrigins.includes(event.origin)) {
        let data = JSON.parse(event.data)
        if (data !== undefined && data.Status) {
          const params = { response: data, accept: config.accept, invoiceData: config.invoiceData }
          dispatch(sendEnrollment(params))
        }
      }
    }
    const eventListener = window.addEventListener('message', handler, false)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])
  return (
    <>
      <iframe name={'ddc-iframe'} height={1} width={1} style={{ display: 'none' }}></iframe>
      <form ref={formRef} action={action} method="POST" target={'ddc-iframe'}>
        <input type="hidden" name="JWT" value={jwt} />
      </form>
    </>
  )
}
export default FormPost
