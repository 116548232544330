import { Box, ClickAwayListener, Grid, Hidden, Snackbar, Tooltip } from '@material-ui/core'
import Footer from 'components/Footer'
import FooterInterestAndFines from 'components/FooterInterestAndFines'
import { usePaymentContext } from 'context/PaymentContext'
import invoiceApi from 'libs/infinitepay/invoice'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CopyButton, PixAlert, PixError, PixLoading, WithoutPixView } from './styles'

const PixQRCode = dynamic(() => import('./PixQRCode'), {
  ssr: false,
})

export const PixMethod = ({}) => {
  const { invoiceData, userData, setInvoiceData } = usePaymentContext()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const { 'md[rid]': rid } = router.query

  useEffect(() => {
    invoiceApi.renewProgressiveLongPolling()
    if (!invoiceData?.pix?.code) {
      const createPixInvoice = async () => {
        const response = await invoiceApi.createPixInvoice(userData.id, invoiceData.slug, rid)
        if (response?.success) {
          setInvoiceData(response.data)
        } else {
          setData(-1)
          setLoading(false)
        }
      }
      createPixInvoice()
    } else {
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    if (invoiceData?.pix?.code) {
      setData(invoiceData.pix.code)
      setLoading(false)
    }
  }, [invoiceData, invoiceData?.pix?.code])
  const handleTooltipClose = () => {
    open && setOpen(false)
  }

  const handleTooltipOpen = () => {
    if (data === -1 && loading) return
    setOpen(true)
    navigator.clipboard.writeText(data)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return userData.has_pix ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <Box
            color="#212121"
            fontWeight="400"
            textAlign="center"
            fontSize="14px"
            lineHeight="18.2px"
          >
            Escaneie o QR Code ou pague com o<br />
            <b>Pix Copia e Cola</b> no seu app de pagamento.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            height={220}
            width={220}
            margin="0 auto"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            overflow="hidden"
            borderRadius="1rem"
            boxShadow="0px 16px 32px rgba(18, 18, 18, 0.08)"
            alignItems="center"
          >
            {loading && <PixLoading />}
            {data === -1 && <PixError />}
            {!loading && data && data !== -1 && <PixQRCode data={data} />}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ClickAwayListener disableReactTree={true} onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copiado!"
            >
              <div>
                <CopyButton
                  disabled={data === -1 || loading}
                  onClick={handleTooltipOpen}
                  loading={loading}
                >
                  Pix Copia e Cola
                </CopyButton>
              </div>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item xs={12}>
          <Box
            color="#757575"
            fontWeight="400"
            textAlign="center"
            fontSize="14px"
            lineHeight="18.2px"
          >
            Após o pagamento, aguarde a página atualizar
            <br />
            com o comprovante de pagamento.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FooterInterestAndFines invoiceData={invoiceData} />
          <Hidden mdUp>
            <Footer
              style={{
                margin: '0 auto',
              }}
            />
          </Hidden>
          <br />
        </Grid>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <PixAlert onClose={handleClose} />
        </Snackbar>
      </Grid>
    </>
  ) : (
    <WithoutPixView handle={userData.fantasy_name} />
  )
}
